<template>
  <section>
    <Breadcrumbs
      :breadcrumbs="breadcrumbs"
      class="mb-8"
    />
    <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Employer questions
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            As part of your assessments, {{ job.organisationName }} has asked if you can answer these specific questions.
          </p>
        </div>
      </div>
      <div class="col-span-2">
        <div class="rounded-lg bg-white overflow-hidden shadow p-6 relative">
          <template v-if="!submitted">
            <ol
              v-if="formResponses.personalQuestions.length"
              class="divide-y divide-dashed space-y-6 -mt-6"
            >
              <li
                v-for="(question, questionIndex) in formResponses.personalQuestions"
                :key="question.uuid"
              >
                <label
                  :for="`question-${question.uuid}`"
                  class="block text-lg font-medium text-gray-700 my-6"
                >
                  {{ question.question }}
                </label>

                <textarea
                  :id="`question-${question.uuid}`"
                  v-model="$v.formResponses.personalQuestions.$each[questionIndex].body.$model"
                  placeholder="Enter your answer here"
                  class="form-textarea w-full focus:border-gray-800 focus:shadow-none resize-none rounded-lg"
                  rows="6"
                />
                <ErrorsInline v-if="hasErrors">
                  <span v-if="!$v.formResponses.personalQuestions.$each[questionIndex].body.required">This field is required</span>
                </ErrorsInline>
              </li>
            </ol>
          </template>
          <template v-else>
            <p class="text-lg">
              Answers submitted.
            </p>
          </template>

          <template v-if="!formResponses.personalQuestions.length">
            <p class="text-lg">
              Answers submitted.
            </p>
          </template>

          <div
            v-if="!submitted && formResponses.personalQuestions.length"
          >
            <div class="flex justify-end mt-8">
              <BaseButton
                variant="ghost"
                class="md:w-24 mr-4"
                @click="$router.push({ name: 'candidate-wizard-dashboard' })"
              >
                Cancel
              </BaseButton>
              <BaseButton
                class="md:w-24"
                :disabled="processing"
                :loading="processing"
                @click="submit"
              >
                Save
              </BaseButton>
              <ErrorsInline
                v-if="errorMessage"
                class="right-6"
              >
                <span>{{ errorMessage }}</span>
              </ErrorsInline>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PersonalQuestionsCompleteModal
      :modal-open="personalQuestionsComplete"
      @close="closeModal"
    />
  </section>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import Breadcrumbs from '@components/Breadcrumbs'
import PersonalQuestionsCompleteModal from '@components/CandidateWizard/PersonalQuestionsCompleteModal'

import candidateWizardApi from '@api/candidateWizard'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    ErrorsInline,
    Breadcrumbs,
    PersonalQuestionsCompleteModal
  },

  mixins: [validationMixin],

  props: {
    candidate: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      processing: false,
      hasErrors: false,
      errorMessage: '',
      submitted: false,
      formResponses: {
        personalQuestions: []
      },
      personalQuestionsComplete: false
    }
  },

  validations: {
    formResponses: {
      personalQuestions: {
        $each: {
          uuid: {},
          body: {
            required
          }
        }
      }
    }
  },

  computed: {
    /**
     * @return {Object}
     */
    job() {
      return this.candidate.jobs.find(job => job.wizardSlug === this.$route.params.job)
    },

    /**
     * @return {Object}
     */
    breadcrumbs() {
      return [
        {
          name: 'Dashboard',
          link: 'candidate-wizard-dashboard'
        },
        {
          name: 'Q&A',
          link: 'candidate-wizard-personal-questions'
        }
      ]
    }
  },

  created() {
    if (this.job.personalQuestions) {
      // Filter out questions that have already been answered
      const personalAnswerUuids = this.candidate.personalAnswers.map(answer => answer.personalQuestionUuid)
      const questions = this.job.personalQuestions.filter(question => !personalAnswerUuids.includes(question.uuid))

      const answers = questions.map(questionObject => {
        return {
          question: questionObject.body,
          personalQuestionUuid: questionObject.uuid,
          body: ''
        }
      })
      this.formResponses.personalQuestions = Object.assign([], answers)
    }
  },

  methods: {
    submit() {
      this.errorMessage = ''
      this.processing = true
      this.$v.formResponses.personalQuestions.$touch()
      this.hasErrors = this.$v.formResponses.$anyError

      if (this.hasErrors) {
        this.processing = false
        return
      }

      // We dont need to send the question back to the API
      const answers = this.formResponses.personalQuestions.map(({ question, ...everythingElse }) => everythingElse)

      return candidateWizardApi.personalAnswers({ answers: answers, jobUuid: this.job.uuid })
        .then(response => {
          this.processing = false
          this.submitted = true
          this.personalQuestionsComplete = true
        })
        .catch(error => {
          this.errorMessage = 'Apologies. Something went wrong. Please try again.'
          this.processing = false
          throw error
        })
    },

    closeModal() {
      this.personalQuestionsComplete = false
      this.$router.push({ name: 'candidate-wizard-dashboard' })
    }
  }
}
</script>
